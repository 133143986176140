import styled from 'styled-components';

export const StyledProjectInfoContainer = styled.div`
    width: 100vw;
    height: 100%;
    background: #F2F2F2;
    position: relative;

    .projectInfoPanel {
        margin-top: 40px;
        width: 1020px;
        height: 100%;
    }

    .projectTopPanel {
        margin-bottom: 126px;
        width: 100%;
        height: 700px;
    }

    .projectBottomPanel {
        width: 100%;
        height: auto;
        background-color: #fff;
        padding: 15px;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.2);
        position: fixed;
        left:0;
        bottom: 0;
        .inner{
            max-width: 1020px;
            margin: 0 auto;
        }
    }

    .projectStatusPanel {
        width: 328px;
        height: 100%;
        background: #FFFFFF;
        border: 1px solid #E6E6E6;
        border-radius: 8px;
    }

    .modelImage {
        margin-bottom: 30px;
        width: 280px;
        height: 182px;
        background: #F2F2F2;
        border-radius: 8px;
        position:relative;
        overflow:hidden;
        .picholder{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background: #F2F2F2;
            border-radius: 8px;
        }
        img{
            width:280px;
            height:182px;
            object-fit:cover;
        }
        svg {
            width: 80px;
            height: 80px;
            path {
                fill:  #C5C5C5;
            }
        }
    }

    .detailPanel {
        width: 280px;
        height: 24px;
        margin-bottom:20px;

        .detailIcon {
            margin-right: 8px;
            width: 17px;
        }

        .detailText {
            font-size: 14px;
        }

        .detailType {
            padding: 1px 16px;
            background: #FF5E8F;
            border-radius: 24px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            margin-left: auto;
        }

        .detailContent {
            margin-left: auto;
            font-size: 14px;
        }

        .draftText {
            font-size: 12px;
            width: 55px;
            height: 20px;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 24px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .publicTrue{
            background: #2BB8AF;
        }

        .createdDate, .lastPublish, .lastEdit {
            color: #E01D62;;
            font-size: 14px;
            font-weight: 500;
        }

        .nullDate {
            color: rgba(0, 0, 0, 0.2) !important;
        }
    }

    .projectFormPanel {
        width: 668px;
        height: 100%;
        background: #FFFFFF;
        border: 1px solid #E6E6E6;
        border-radius: 8px;
    }

    .projectNamePanel {
        margin-top: 26px;
        margin-bottom: 42px;
        padding: 0px 24px;
        width: 100%;
        height: 80px;
        box-sizing: border-box;
        position: relative;
        .projectLabelPanel {
            margin-bottom: 10px;
            width: 100%;
            height: 20px;

            .projectLabelText {
                font-size: 14px;
            }

            span {
                color: red;
            }
        }
        .projectLableTextCounts {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
            position: absolute;
            right: 30px;
            top:5px;
        }

        .projectNameInput {
            font-family: inherit;
            padding: 8px 16px;
            width: 100%;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 2px;
            font-size: 14px;
            font-weight: 500;
            box-sizing:border-box;
            outline-color: #FF744E;
            outline-width: 1px;
            &:hover {
                border: 1px solid #FF744E;
                border-radius: 2px;
            }
            &:focus{
                border: 1px solid #FF744E;
                box-shadow: 0px 0px 0px 4px rgba(255, 116, 78, 0.15);
                border-radius: 2px;
            }
            &:focus + .projectLableTextCounts{
                color: #FF744E;
            }
            
        }
        .projectNameInputErr{
            border:1px solid #F5314D;
            outline-color:#F5314D;
            &:focus{
                border: 1px solid #F5314D;
                box-shadow: 0px 0px 0px 4px rgba(245, 49, 77, 0.15);
                border-radius: 2px;
            }
        }

        .projectNameInputErr + .projectLableTextCounts{
                color: #F5314D;
            }

        .projectNameInputError {
            font-size: 12px;
            color: #F5314D;
            padding-left: 10px;
            padding-top: 5px;
        }
    }

    .projectDescPanel {
        width: 100%;
        height: 122px;
        padding: 0px 24px;
        box-sizing: border-box;
        position: relative;
        .descLabel {
            margin-bottom: 10px;
            width: 100%;
            text-align: left;
            font-size: 14px;
            box-sizing:border-box;
            display:flex;
            justify-content:space-between;
        }

        .projectDesc {
            font-family: inherit;
            resize: none;
            min-width: 100%;
            max-width: 618px;
            height: 100px;
            max-width: 100px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 2px;
            padding:8px 16px;
            box-sizing: border-box;
            outline-color: #FF744E;
            outline-width: 1px;
            &:hover {
                border: 1px solid #FF744E;
                border-radius: 2px;
            }
            &:focus{
                border: 1px solid #FF744E;
                box-shadow: 0px 0px 0px 4px rgba(255, 116, 78, 0.15);
                border-radius: 2px;
            }
            &:focus + .descriptLength{
                color: #FF744E;
            }
        }
        .descriptLength{
            font-size: 12px;
            color: rgba(0,0,0,0.6);
            position: absolute;
            right: 30px;
            top:5px;
        }
    }

    .projectInfoBtn {
        width: 122px;
        height: 48px;
        border-radius: 8px;
        &:hover {
            cursor: pointer;
        }
    }
    .cancelBtn{
        width:80px;
    }

    .lds-dual-ring {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right:2px;
    }

    .lds-dual-ring:after {
        position: relative;
        content: " ";
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid #fff;
        border-color: #fff transparent #fff transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .reverseBtn {
        flex-direction: row-reverse;
    }

    .reuploadBtn {
        background: #F2F2F2;
        color: #666666;
        text-decoration: none;
        &:hover{
            background: #E0E0E0;
        }
    }

    .saveBtnPanel {
        .onlySave {
            padding:12px 24px;
            border-radius: 8px;
        }

        .saveRedirect {
            height:48px;
            width: auto;
            padding:0px 22px;
        }

        .editOnlySave, .createSaveRedirect {
            background: #ED2975;
            color: #fff;
            &:hover{
                background-color:#F2539D;
            }
        }

        .editSaveRedirect, .createOnlySave {
            background: #fff;
            color: #ED0973;
            border: 1px solid #ED0973;
            &:hover{
                background-color: #F2539D;
                border: 1px solid #F2539D;
                color: #fff;
            }
        }

        > :nth-child(1) {
            margin-right: ${props => props.mode === 'edit' ? '0px' : '24px'};
            /* background: #fff;
            color: #ED2975;
            border: 1px solid #ED2975; */
        }

        > :nth-child(2) {
            margin-right: ${props => props.mode === 'edit' ? '24px' : '0px'};
            
        }

        .editOnlySaveSpinner:after, .createSaveRedirectSpinner:after {
            border: 2px solid #fff !important;
            border-color: #fff transparent #fff transparent !important;
        }

        .createOnlySaveSpinner:after, .editSaveRedirectSpinner:after {
            border: 2px solid #ED2975 !important;
            border-color: #ED2975 transparent #ED2975 transparent !important;
        }

        

    }

    .pubCtrl{
        padding-top: 50px;
        a{
            width: 296px;
            height: 32px;
            display: block;
            margin-bottom: 15px;
            border-radius: 4px;
            text-align: center;
            line-height: 32px;
            font-size: 14px;
            text-decoration: none;
            &:first-child{
                background: #E01D62;
                color: #fff;
                &:hover{
                    opacity: 0.7;
                }
            }
        }
    }

    .disableEditOnlySaveBtn, .disableCreateOnlySaveBtn {
        background:#FAB5D5 !important;
        border: 1px solid #FAB5D5 !important;
        color: #fff !important;
        border-radius: 8px;

        &:hover {
            cursor: not-allowed;
        }
    }

    .disableEditSaveRedirectBtn, .disableCreateSaveRedirectBtn {
        background: #FFFFFF !important;
        border: 1px solid #FAB5D5 !important;
        color:#FAB5D5 !important;
        border-radius: 8px;

        &:hover {
            cursor: not-allowed;
        }
    }
`;

export const StyledPanelNotch = styled.div`
    margin-bottom: 20px;
    width: 100%;
    height: 8px;
    background: #FF5E8F;
    border-radius: 8px 8px 0px 0px;
`;