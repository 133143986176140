// api
import axioApi, { setHeader } from 'api/root.api';

export const modelUpload = async (body, token, projectId, modelId, fileType, handleUploadBar) => {
    const urlEnd = fileType === 'gltf' ? `/editor/project/v1/${projectId}/${modelId}/uploadGltf` : `/editor/project/v1/${projectId}/${modelId}/uploadGlb`;
    setHeader('Authorization', `Bearer ${token}`);
    const response = await axioApi.post(urlEnd, body, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
            handleUploadBar(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        },
    });
    const result = response.data;
    return result;
};