// react
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// styled components
import { StyledProjectInfoContainer, StyledPanelNotch } from './StyledProjectInfo';

//redux
import { SET_CARD_LIST } from 'redux/types';

// components
import TopBar from 'components/TopBar/TopBar';
import ModalPopup from 'components/ModalPopup/ModalPopup';
import SmallLoadIngIcon from 'components/SmallLoadIngIcon/SmallLoadIngIcon';
import SuccessProject from 'components/SuccessProject/SuccessProject';
import Loading from 'components/Loading/Loading';


// assets
import { ReactComponent as ModelIcon } from './icons/model-icon.svg';

// hooks
import useModal from 'hooks/useModal';

// config
import { modalConfig } from 'config/modal';

// react icons
import { BsCheckLg } from 'react-icons/bs';

// api
import { getProject } from 'api/getProject/getProject.api';
import { getProjectList } from 'api/getProjectList/getProjectList.api';
import { reviseProject } from 'api/reviseProject/reviseProject.api';
import { openProject } from 'api/openProject/openProject.api';
import { closeProject } from 'api/closeProject/closeProject.api';

// plugins
import Cookies from 'js-cookie';
import * as dayjs from 'dayjs';
import clsx from 'classnames';

// image
import dataIcon from './icons/text-label.svg';
import line from './icons/line.svg';
import file from './icons/file.svg';
import upload from './icons/upload.svg';
import pen from './icons/pen.svg';

//i18n
import { useTranslation } from 'react-i18next';

import SERVER from 'constants/server';
import { RiCreativeCommonsSaLine } from 'react-icons/ri';

const PanelNotch = () => {
    return (
        <StyledPanelNotch className="panelNotch"></StyledPanelNotch>
    );
};

const ProjectInfo = () => {

    const store = useSelector(store => store);
    const dispatch = useDispatch();
    const { modalSetting, setModalSetting } = useModal();

    const [name, setName] = useState('');
    const [descript, setDescript] = useState('');
    const [nameLength, setNameLength] = useState(0);
    const [publics, setPublics] = useState('草稿');
    const [publicStatus, setPublicStatus] = useState(false);
    const [createTime, setCreateTime] = useState('');
    const [lastUpdate, setLastUpdate] = useState('');
    const [lastPublic, setLastPublic] = useState(null);
    const [isFirst, setFirst] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isOnlySaveApiLoading, setIsOnlySaveApiLoading] = useState(false);
    const [isSaveRedirectApiLoading, setIsSaveRedirectApiLoading] = useState(false);
    const [isOnlySaved, setIsOnlySaved] = useState(false);
    const [isSaveRedirectSaved, setIsSaveRedirectSaved] = useState(false);
    const [alreadySave, setAlreadySave] = useState(false);
    const [successPopup, setSuccessPopup] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(false);
    const [uuid, SetUuid] = useState(store.cardListInfo.cardListDetail[Cookies.get('projectIndex')]?.uuid);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const getFileName = urlParams.get('fileName');
    const getMode = urlParams.get('mode');
    const getuseMaterialLab = urlParams.get('useMaterialLab');
    const [isPublish, setPublish] = useState(false);
    const [icon, setIcon] = useState('');
    const [isLoadingPublish, setIsLoadingPublish] = useState(false);
    const { t, i18n } = useTranslation();
    const [loadingTitle, setLoadingTitle] = useState('');
    const [loadingTxt, setLoadingTxt] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [picAlready, setPicAlready] = useState(false);

    const { publicProject: publicProjectLimit } = useSelector(store => store.permits);
    const { projectPublicCount } = useSelector(store => store.projectRecord);


    const handleErrorResponse = (modalType) => {
        setModalSetting({
            ...modalSetting,
            show: true,
            title: '',
            type: modalType,
            handleConfirm: () => { },
        });
    };


    useEffect(() => {
        if (getuseMaterialLab) {
            let body = {
                description: descript,
                hashTags: [],
                id: Cookies.get('projectId'),
                name: getFileName,
            };
            reviseProject(body, Cookies.get('token'));
        }

        getProject(Cookies.get('token'), Cookies.get('projectId')).then((res) => {
            for (let item of res.project.editors[0].models) {
                if (item.mainObject) {
                    if (item.modelThumb) {
                        setIcon(item.modelThumb);
                        let image = new Image();
                        image.src = item.modelThumb;
                        image.onload = () => {
                            setPicAlready(true);
                        };
                    } else if (item.icon) {
                        setIcon(item.icon);
                        let image = new Image();
                        image.src = item.icon;
                        image.onload = () => {
                            setPicAlready(true);
                        };
                    } else {
                        setIcon('');
                        setPicAlready(true);
                    }
                    break;
                }
            }
            if (getMode === 'create') {
                if (getFileName) {
                    setName(getFileName);
                }

                if (!getFileName) {
                    setName(res.project.name);
                }
            }

            if (getMode === 'edit') {
                setName(res.project.name);
            }
            setCreateTime(res.project.dateCreated);
            // console.log(res);
            setCreateTime(dayjs(res.project.dateCreated).format('YYYY/MM/DD'));
            setLastUpdate(dayjs(res.project.lastUpdated).format('YYYY/MM/DD'));
            // setCreateTime(dayjs(res.project.dateCreated).format('YYYY/MM/DD'));
            setDescript(res.project.description);
            setPublish(res.project.editors[0].publicSwitch);
            if (res.project.editors[0].publicSwitch === false) {
                setPublics(t('draft'));
                setPublicStatus(false);
            } else {
                setPublicStatus(true);
                setPublics(t('public'));
            }
            if (res.project.editors[0].lastPublic === null) {
                setLastPublic('----/--/--');
            } else {
                setLastPublic(dayjs(res.project.editors[0].lastPublic).format('YYYY/MM/DD'));
            }

            if (window.location.search.indexOf('create') > -1) {
                setFirst(false);
            } else {
                setFirst(true);
            }
        }).catch((err) => {
            handleErrorResponse('type23');
        });
    }, []);

    useEffect(() => {
        setNameLength(name.length);
    }, [name]);

    const changeName = (e) => {
        setName(e.target.value);
        setIsLoaded(true);
    };

    const changeDescript = (e) => {
        setDescript(e.target.value);
    };

    const navigate = useNavigate();


    const handleOnlySaved = () => {
        setIsOnlySaved(true);
        setTimeout(() => {
            setIsOnlySaved(false);
        }, 2500);
    };


    const handleSaveRedirectSaved = () => {
        setIsSaveRedirectSaved(true);
        setAlreadySave(true);
        setTimeout(() => {
            setIsSaveRedirectSaved(false);
        }, 2500);
    };

    const childPublishEvent = (value) => {
        setSuccessPopup(value);
    };

    const uploadProjectInfo = (saveMode) => () => {

        if (nameLength === 0) return;
        if (isOnlySaved || isSaveRedirectSaved) return;
        if (isOnlySaveApiLoading || isSaveRedirectApiLoading) return;

        let body = {
            description: descript,
            hashTags: [],
            id: Cookies.get('projectId'),
            name: name,
        };



        if (saveMode === 'save') {
            setIsOnlySaveApiLoading(true);
            setAlreadySave(true);
            reviseProject(body, Cookies.get('token'))
                .then(res => {
                    // navigate('/cardList');
                    handleOnlySaved();
                })
                .catch(err => {
                    handleErrorResponse('type31');
                })
                .finally(() => {
                    setIsOnlySaveApiLoading(false);
                });

            return;
        }

        if (saveMode === 'saveRedirect') {
            setIsSaveRedirectApiLoading(true);
            reviseProject(body, Cookies.get('token'))
                .then(res => {
                    const token = Cookies.get('token');
                    const encodeToken = encodeURIComponent(token);
                    const lang = Cookies.get('lang');
                    window.open(`${SERVER.EDITOR_URL}/?projectId=${res.project.id}&modelsId=${res.project.editors[0].models[0].id}&token=${encodeToken}&isPublish=${res.project.editors[0].publicSwitch}&lang=${lang}`, '_self');

                    handleSaveRedirectSaved();
                    // navigate('/cardList');
                })
                .catch(err => {
                    handleErrorResponse('type23');
                })
                .finally(() => {
                    setIsSaveRedirectApiLoading(false);
                });

        }
        return;
    };

    const projectInfoPublish = (e) => {
        e.preventDefault();
        const token = Cookies.get('token');
        const projecId = Cookies.get('projectId');
        let getUid = store.cardListInfo.cardListDetail.filter(item => item.id === parseInt(projecId));
        const encodeUid = encodeURIComponent(getUid[0].uuid);
        SetUuid(encodeUid);
        // console.log(encodeUid);
        setIsLoadingPublish(true);

        if (!isPublish) {
            // 物件是未公開狀態，可執行「公開」功能
            setLoadingTitle(t('publishingProject'));
            setLoadingTxt(t('pleaseWait'));
            setLoadingStatus(true);

            // 可公開物件達上限，跳出 popup
            if (projectPublicCount >= publicProjectLimit) {
                setLoadingStatus(false);
                setIsLoadingPublish(false);
                setModalSetting({
                    ...modalSetting,
                    show: true,
                    type: store.permits.plan.indexOf('free') > -1 ? 'type19' : 'type40',
                    desc: t('publicFail', { projectCount: projectPublicCount, projectLimit: publicProjectLimit }),
                    handleConfirm: () => {
                        if (store.permits.plan.indexOf('free') > -1) {
                            navigate('/cardList?upgrade=true');
                        } else {
                            window.open(`${SERVER.AR_MAKER}/contact_us?lang=${Cookies.get('lang')}`, '_blank');
                        }
                    },
                });
            } else {
                // 公開物件 api (ARMAKER)
                openProject(projecId, token).then(res => {
                    // 跳轉頁面
                    // window.open(`${SERVER.VIEWER_URL}/?uid=${encodeUid}`, '_blank', 'noopener, noreferrer');
                    setLoadingStatus(false);
                    setIsLoadingPublish(false);
                    setSuccessPopup(true);
                    setPublicStatus(true);
                    setPublics(t('public'));
                    setPublish(true);
                    // window.location.reload();
                }).catch(err => {
                    setLoadingStatus(false);
                    setIsLoadingPublish(false);
                    handleErrorResponse('type24');
                });
            }
        } else {
            // 物件是公開狀態，可執行「更新至公開頁」功能
            let body = {
                description: descript,
                hashTags: [],
                id: Cookies.get('projectId'),
                name: name,
            };
            reviseProject(body, Cookies.get('token')).then(() => {
                closeProject(projecId, token).then(() => {
                    openProject(projecId, token).then(() => {
                        // 跳轉頁面
                        // window.open(`${SERVER.VIEWER_URL}/?uid=${encodeUid}`, '_blank', 'noopener, noreferrer');
                        setIsLoadingPublish(false);
                        setSuccessPopup(true);
                        setUpdateStatus(true);
                        // window.location.reload();
                    });
                });
            }).catch(err => {
                handleErrorResponse('type24');
            });
        }
    };

    const repick = () => {
        if (getuseMaterialLab) {
            if (alreadySave) {
                navigate({
                    pathname: '/upload',
                    search: `?fileName=${name}&useMaterialLab=true`,
                });
            } else {
                navigate({
                    pathname: '/upload',
                    search: `?fileName=${getFileName}&useMaterialLab=true`,
                });
            }
        } else {
            if (alreadySave) {
                navigate({
                    pathname: '/upload',
                    search: `?fileName=${name}`,
                });
            } else {
                navigate({
                    pathname: '/upload',
                    search: `?fileName=${getFileName}`,
                });
            }
        }
    };

    return (
        <StyledProjectInfoContainer className="flexCenter flexY" mode={getMode}>
            {modalSetting.show && (
                <ModalPopup
                    modalConfig={modalConfig[modalSetting.type]}
                    setModalSetting={setModalSetting}
                    modalSetting={modalSetting}
                />
            )}
            {
                successPopup &&
                <SuccessProject uuid={uuid} onCallParent={childPublishEvent} updateStatus={updateStatus} />
            }
            {loadingStatus &&
                <Loading mask='true' title={loadingTitle} txt={loadingTxt} />
            }
            <TopBar
                title={getMode === 'edit' ? t('editProjectInformation') : t('createProjectInformation')}
                isEditMode={getMode === 'edit'} alreadySave={alreadySave}
            />
            <div className="projectInfoPanel">
                <div className="projectTopPanel flexBetweenCenter">
                    <div className="projectStatusPanel flexStartCenter flexY">
                        <PanelNotch />
                        <div className="modelImage flexCenter">
                            {
                                icon === '' ? <ModelIcon /> : <img src={icon} />
                            }
                            {
                                !picAlready && <div className="picholder"></div>
                            }
                        </div>

                        <div className="projectDetailPanel">
                            <div className="detailPanel flexStartCenter">
                                <img src={line} alt="" className="detailIcon" />
                                <div className="detailText">
                                    {t('projectType')}
                                </div>
                                <div className='detailType'>AR Object</div>
                            </div>
                            <div className="detailPanel flexStartCenter">
                                <img src={line} alt="" className="detailIcon" />
                                <div className="detailText">
                                    {t('projectStatus')}
                                </div>
                                <div className={!publicStatus ? 'detailContent draftText' : 'detailContent draftText publicTrue'}>{publics}</div>
                            </div>
                            <div className="detailPanel flexStartCenter">
                                <img src={file} alt="" className="detailIcon" />
                                <div className="detailText">
                                    {t('createTime')}
                                </div>
                                <div className='detailContent createdDate'>{createTime}</div>
                            </div>
                            <div className="detailPanel flexStartCenter">
                                <img src={upload} alt="" className="detailIcon" />
                                <div className="detailText">
                                    {t('latestPublicTime')}
                                </div>
                                <div className={clsx('detailContent', 'lastPublish', {
                                    'nullDate': lastPublic === '----/--/--',
                                })}>{lastPublic}</div>
                            </div>
                            <div className="detailPanel flexStartCenter">
                                <img src={pen} alt="" className="detailIcon" />
                                <div className="detailText">
                                    {t('lastEditTime')}
                                </div>
                                <div className={clsx('detailContent', 'lastEdit', {
                                    'nullDate': !lastUpdate,
                                })}>{lastUpdate}</div>
                            </div>
                        </div>
                        {isFirst &&
                            <div className="pubCtrl">
                                <a href="#" onClick={projectInfoPublish}>
                                    {isLoadingPublish && <SmallLoadIngIcon />}
                                    {isPublish === false ? t('public') : t('updateToPublic')}
                                </a>
                            </div>
                        }
                    </div>
                    <div className="projectFormPanel">
                        <PanelNotch />

                        <div className="projectNamePanel">
                            <div className="projectLabelPanel flexBetweenCenter">
                                <div className="projectLabelText">
                                    {t('projectName')} <span>*</span>
                                </div>
                            </div>
                            <input
                                type="text"
                                onChange={changeName}
                                value={name}
                                maxLength="80"
                                className={nameLength === 0 || nameLength > 80 ? 'projectNameInput projectNameInputErr' : 'projectNameInput'}
                            />
                            <div className="projectLableTextCounts">
                                {nameLength}
                                /
                                80
                            </div>
                            <div className="projectNameInputError flexStartCenter">
                                {nameLength === 0 && <span>{t('enterProjectName')}</span>}
                            </div>
                        </div>

                        <div className="projectDescPanel">
                            <div className="descLabel">
                                <div>{t('projectDescription')}</div>
                            </div>
                            <textarea
                                value={descript}
                                name=""
                                id=""
                                cols="30"
                                rows="10"
                                maxLength={1000}
                                className="projectDesc"
                                onChange={changeDescript}
                            >
                            </textarea>
                            <div className='descriptLength'>
                                {descript.length} / 1000
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className="projectBottomPanel">
                <div className="inner flexBetweenCenter">
                    {
                        getMode === 'edit'
                            ? <Link className="reuploadBtn cancelBtn projectInfoBtn flexCenter" to="/cardListß">{t('cancel')}</Link>
                            : (
                                <div
                                    className="reuploadBtn projectInfoBtn flexCenter"
                                    onClick={repick}
                                >
                                    {getuseMaterialLab ? t('reSelect') : t('reupload')}
                                </div>
                            )
                    }


                    <div className={clsx('saveBtnPanel', 'flexBetweenCenter', {
                        'reverseBtn': getMode === 'edit',
                    })}>
                        <div
                            className={
                                clsx('onlySave', 'flexCenter', {
                                    'disableEditOnlySaveBtn': name.length === 0 && getMode === 'edit',
                                    'disableCreateSaveRedirectBtn': name.length === 0 && getMode === 'create',
                                    'editOnlySave': getMode === 'edit',
                                    'createOnlySave': getMode === 'create',
                                })
                            }
                            onClick={uploadProjectInfo('save')}
                        >
                            {
                                isOnlySaveApiLoading && (
                                    <div className={clsx('lds-dual-ring', {
                                        'editOnlySaveSpinner': getMode === 'edit',
                                        'createOnlySaveSpinner': getMode === 'create',
                                    })}></div>
                                )
                            }
                            {isOnlySaved && <BsCheckLg style={{ marginRight: '2px' }} />}

                            <span className="onlySaveText">{t('save')}</span>
                        </div>

                        <div
                            className={
                                clsx('saveRedirect', 'projectInfoBtn', 'flexCenter', {
                                    'disableEditSaveRedirectBtn': name.length === 0 && getMode === 'edit',
                                    'disableCreateOnlySaveBtn': name.length === 0 && getMode === 'create',
                                    'editSaveRedirect': getMode === 'edit',
                                    'createSaveRedirect': getMode === 'create',
                                })
                            }
                            onClick={uploadProjectInfo('saveRedirect')}
                        >
                            {
                                isSaveRedirectApiLoading && (
                                    <div className={clsx('lds-dual-ring', {
                                        'editSaveRedirectSpinner': getMode === 'edit',
                                        'createSaveRedirectSpinner': getMode === 'create',
                                    })}></div>
                                )
                            }
                            {isSaveRedirectSaved && <BsCheckLg />}
                            <span className="saveRedirectText">{t('saveEdit')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </StyledProjectInfoContainer>
    );
};

export default ProjectInfo;