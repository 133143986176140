import axioApi, { setHeader } from 'api/root.api';

export const deleteTag = async (projectId, modelId, tagId) => {
    const urlEnd = `/editor/project/v1/${projectId}/${modelId}/${tagId}`;
    const response = await axioApi.delete(urlEnd);
    const result = response.data;
    return result;
};

export const createBatchTags = async (body, projectId, modelId) => {
    const urlEnd = `/editor/project/v1/${projectId}/${modelId}/tags`;
    const response = await axioApi.post(urlEnd, body);
    const result = response.data;
    return result;
};

export const createBatchTagsIcon = async (body, projectId, modelId, tagIds) => {
    let urlEnd = `/editor/project/v1/${projectId}/${modelId}/tags/icon?`;
    tagIds.forEach((id, index) => {
        if (index === tagIds.length - 1) {
            urlEnd = `${urlEnd}tagIds=${id}`;
            return;
        }
        urlEnd = `${urlEnd}tagIds=${id}&`;
    });
    setHeader('Content-Type', 'multipart/form-data');
    const response = await axioApi.post(urlEnd, body);
    const result = response.data;
    return result;
};

export const updateBatchTags = async (body, projectId, modelId) => {
    const urlEnd = `/editor/project/v1/${projectId}/${modelId}/tags/update`;
    const response = await axioApi.post(urlEnd, body);
    const result = response.data;
    return result;
};