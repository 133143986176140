import axioApi, { setHeader } from 'api/root.api';

export const uploadFile = async (body, fileType, token, projectId, modelId, handleUploadBar) => {
    const type = (fileType === 'video') ? '50/scanFilesVideo' : 'scanFiles';
    const urlEnd = `/scan/editor/project/v1/${projectId}/${modelId}/${type}`;
    setHeader('Authorization', `Bearer ${token}`);
    setHeader('Content-Type', 'multipart/form-data');
    const response = await axioApi.post(urlEnd, body, {
        onUploadProgress: progressEvent => {
            handleUploadBar(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        },
    });
    const result = response.data;
    return result;
};